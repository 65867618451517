<template>
    <div class="modal-profile-item-selector edit-profile-detail items-start bg-main">
        <div class="back-button-wrapper" @click="onClickClose">
            <img
                class="m-r-2 back-button"
                :src="require(`@/assets/images/icons/close.png`)"
                width="24px"
                height="24px"
            />
            <span class="f-18 m-l-12 c-black">지역 선택</span>
        </div>
        <div class="title" :class="'m-b-16'" v-html="'지역 검색'" />
        <div class="input-wrapper items-center">
            <img class="m-r-4" :src="require('@/assets/images/icon-search.png')" alt="" />
            <input ref="searchValue" v-model="searchValue" @input="onKeydown" :placeholder="'지역을 입력해주세요.'" />
            <i v-if="searchValue" @click="searchValue = ''" class="material-icons">cancel</i>
        </div>
        <div class="no-search-result m-l-20 flex-wrap" v-if="noResult">
            <i class="material-icons-outlined c-danger m-r-4">info</i>
            <span v-html="$translate('NO_SEARCH_RESULT')" />
        </div>
        <div v-if="!searchValue" class="empty-search-value">
            <i class="material-icons">search</i>
            <span class="c-black"><b>키워드를 입력해보세요</b></span>
            <span>'가로수길', '합정', '강남역'등을 입력할 수 있어요</span>
        </div>
        <ul v-else class="result flex-fill">
            <li
                @click="onClickItem(item)"
                class="item flex-row items-center flex-between"
                :class="{ selected: item.$$selected }"
                :key="item.id"
                v-for="item in regionList"
            >
                <div v-html="$translate(getVal(item))" />
                <img
                    v-if="getVal(item)"
                    class="i-right"
                    :src="require(`@/assets/images/icons/arrow_right.svg`)"
                    width="16px"
                    height="16px"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import debounce from '@/modules/debounce'
import scheduleService from '@/services/schedule'

export default {
    name: 'ModalPromiseRegion',
    props: ['options'],
    data: () => ({
        searchValue: '',
        regionList: [],
    }),
    watch: {
        searchValue(newVal) {
            if (!newVal) this.search()
        },
    },
    computed: {
        noResult() {
            return this.searchValue && this.regionList.length <= 1 && !this.regionList[0].id
        },
    },
    mounted() {
        this.search()
    },
    methods: {
        search: debounce(async function () {
            try {
                this.$loading(true)
                const res = await scheduleService.getRegionList(this.searchValue)
                this.regionList = [...res]

                if (this.regionList.length === 0) {
                    this.regionList = [{ name: this.searchValue }]
                }
                this.$loading(false)
            } catch (e) {
                console.log(e)
                this.$toast.error(e.data)
            }
        }, 200),
        onKeydown() {
            setTimeout(() => {
                this.searchValue = this.$refs.searchValue.value
                this.search(this.searchValue)
            })
        },
        getVal(o) {
            if (!o.id) {
                return `'${this.searchValue}'로 입력하기`
            }

            return o.name
        },
        onClickItem(item) {
            this.$set(item, '$$selected', !item.$$selected)

            setTimeout(() => {
                this.$emit('close', item)

                this.$nativeBridge.postMessage({
                    action: 'setBackgroundColor',
                    value: {
                        top: '#FAF0E1',
                        bottom: '#FFF9EF',
                    },
                })

                this.regionList.forEach(o => this.$set(o, '$$selected', false))
            }, 200)
        },
        onClickClose() {
            this.$emit('close')

            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: {
                    top: '#FAF0E1',
                    bottom: '#FFF9EF',
                },
            })
        },
    },
    created() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFF9EF',
        })
    },
}
</script>
